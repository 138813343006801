import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#app/utils/misc'

const buttonVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-regular transition-colors focus-visible:outline-none disabled:pointer-events-none transition duration-150 ease-in-out',
	{
		variants: {
			variant: {
				primary:
					'bg-button-primary hover:bg-button-primary-hover focus:bg-button-primary-focus active:bg-gradient-active-primary active:text-button-primary-active-foreground disabled:bg-button-primary-disabled text-button-primary-foreground hover:text-button-primary-hover-foreground focus:text-button-primary-focus-foreground disabled:text-button-primary-disabled-foreground',
				destructive:
					'bg-destructive text-destructive-foreground hover:bg-destructive/90',
				outline:
					'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
				secondary:
					'bg-button-secondary active:bg-gradient-active-secondary active:text-button-secondary-active-foreground border border-button-secondary-border hover:bg-button-secondary-hover disabled:bg-button-secondary-disabled text-button-secondary-foreground hover:text-button-secondary-hover-foreground disabled:text-button-secondary-disabled-foreground',
				ghost: 'hover:bg-accent hover:text-accent-foreground',
				link: 'text-primary underline-offset-4 hover:underline',
				filter_button:
					'flex justify-between font-size items-center border-beige-90 rounded-lg border-[1px] whitespace-nowrap px-4 md:px-6 py-2 md:py-4 flex-shrink',
			},
			size: {
				default: '',
				primary: 'h-14 px-5 lg:w-fit w-full md:px-16 py-6 text-lg',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10',
				l: 'w-full py-6 md:w-[330px]', // This is L size from design
			},
			fullWidth: {
				true: 'w-full lg:w-full',
			},
		},

		defaultVariants: {
			variant: 'primary',
			size: 'default',
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, fullWidth, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, fullWidth, className }))}
				ref={ref}
				{...props}
			/>
		)
	},
)
Button.displayName = 'Button'

export { Button, buttonVariants }
